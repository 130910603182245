<script setup lang="ts">
const { $script } = useGateway()

const appConfig = useAppConfig()

interface WidgetCalculate {
  code: string
  amount: number
}

defineProps<{
  list?: {
    text: string
  }[]
  link?: {
    title: string
    url: string
    target: string
  }
}>()

const { data: fiatCurrencies } = await useFetch('/api/gateway/currencies/fiats')

const { data: cryptoCurrencies } = await useFetch('/api/gateway/currencies/cryptos')

const ui = {
  ring: 'ring-1 ring-gray-700',
  background: 'bg-gray-800',
  option: {
    color: 'text-gray-300',
    active: 'bg-gray-700',
    selectedIcon: {
      base: 'size-4 text-gray-300 flex-shrink-0',
    },
  },
}

const widgetAmount = computed(() => appConfig.widget.amount)
const widgetCurrency = computed(() => appConfig.widget.currency.crypto.code || appConfig.widget.currency.crypto)
const widgetCurrencyFiat = computed(
  () => appConfig.widget.currency.object.code,
)

const { data, pending } = await useFetch<WidgetCalculate>(() => '/api/gateway/calculate',
  {
    body: {
      currency: widgetCurrency,
      offeredAmount: widgetAmount,
      offeredCurrencyCode: widgetCurrencyFiat,
    },
    method: 'POST',
  },
)

const widgetLoading = ref(false)

async function onOpen() {
  widgetLoading.value = true

  await $script.load()
}

onMounted(() => {
  window.addEventListener('ari10-transaction-window-loaded-event', () => {
    window.dispatchEvent(
      new CustomEvent('ari10-widget-start-transaction-request', {
        detail: {
          buyCurrencyCode: widgetCurrency.value,
          offerMoney: {
            amount: widgetAmount.value,
            currencyCode: widgetCurrencyFiat.value,
          },
        },
      }),
    )
  })
})
</script>

<template>
  <section class="max-w-6xl w-full mx-auto flex flex-col lg:flex-row p-8 gap-8 items-start rounded-xl shadow-2xl border border-gray-500 bg-gradient-to-br from-gray-800 to-gray-950">
    <UForm class="flex-1 flex flex-col w-full gap-4">
      <UInput
        v-model.number="appConfig.widget.amount"
        class="bg-gray-800 border border-gray-700 rounded text-gray-400 dark"
        variant="none"
        size="xl"
        :ui="{ icon: { trailing: { pointer: '' } } }"
      >
        <template #trailing>
          <USelectMenu
            v-slot="{ open }"
            v-model="appConfig.widget.currency.object"
            :ui
            :options="fiatCurrencies"
            option-attribute="code"
          >
            <UButton
              color="white"
              size="xs"
            >
              <UAvatar
                size="3xs"
                :src="appConfig.widget.currency.object.avatar.src"
              />
              {{ appConfig.widget.currency.object.code }}
              <UIcon
                name="i-heroicons-chevron-down-20-solid"
                class="size-5 transition-transform"
                :class="[open && 'transform rotate-180']"
              />
            </UButton>
          </USelectMenu>
        </template>
      </UInput>
      <UInput
        v-model="data.amount"
        disabled
        class="bg-gray-800 border border-gray-700 rounded text-gray-400 dark"
        variant="none"
        size="xl"
        :ui="{ icon: { trailing: { pointer: '' } } }"
      >
        <template #trailing>
          <USelectMenu
            v-slot="{ open }"
            v-model="appConfig.widget.currency.crypto"
            :ui
            :options="cryptoCurrencies"
            option-attribute="code"
          >
            <UButton
              color="white"
              size="xs"
            >
              <UAvatar
                size="3xs"
                :src="appConfig.widget.currency.crypto.imageUrl || 'https://ari10-widget.s3.amazonaws.com/prd/icons/BTCIcon.png'"
              />
              {{ appConfig.widget.currency.crypto.code || appConfig.widget.currency.crypto }}
              <UIcon
                name="i-heroicons-chevron-down-20-solid"
                class="size-5 transition-transform"
                :class="[open && 'transform rotate-180']"
              />
            </UButton>
          </USelectMenu>
        </template>
      </UInput>
      <UButton
        :loading="pending || widgetLoading"
        block
        variant="solid"
        size="xl"
        icon="i-heroicons-arrow-path-rounded-square"
        @click="onOpen"
      >
        Kupuję
      </UButton>
    </UForm>
    <div class="flex-1 text-gray-50 text-xs tracking-tight leading-snug space-y-2 font-medium w-full">
      <p
        v-for="item, index in list"
        :key="item.text"
      >
        <span class="bg-gradient-to-tr from-primary to-gray-950 bg-clip-text text-transparent font-mono text-sm">{{ index + 1 }}.</span>
        {{ item.text }}
      </p>
      <div
        v-if="link"
        class="!mt-8 text-center"
      >
        <UButton
          :to="link.url"
          :target="link?.target"
          variant="link"
          size="xl"
        >
          {{ link.title }}
        </UButton>
      </div>
    </div>
  </section>
</template>
