export function useGateway() {
  return useScript('https://ari10-widget.s3.eu-west-1.amazonaws.com/embedded-production-scripts/source/modern/index.js?v=01082024', {
    use() {
      // @ts-expect-error these variable are required by the script and do not exist in window
      window.widget_id_6851681344231 = '4f884745-7d1d-4d14-9d13-3f47ded0ae21'
      // @ts-expect-error these variable are required by the script and do not exist in window
      window.widget_language_1776290735652 = 'pl'
    },
    trigger: 'manual',
    bundle: true,
  })
}
