<script setup lang="ts">
import type { PageLayoutProps } from '@/types'

const props = defineProps<PageLayoutProps>()

const coloredTitle = computed(() => {
  const words = props.title.split(' ')

  if (words.length >= 2) {
    const first = words.slice(0, -1).join(' ')
    const last = words.slice(-1).join(' ')

    return [first, last]
  }
  else {
    return [props.title, '']
  }
})
</script>

<template>
  <div>
    <div class="bg h-full flex items-center justify-center pt-6 sm:pt-12">
      <UContainer class="flex flex-col ">
        <section class="max-w-5xl mx-auto space-y-4 mb-6">
          <h1 class="text-4xl text-balance text-center font-bold text-gray-950 tracking-tight leading-snug">
            {{ coloredTitle[0] }} <span
              class="bg-gradient-to-tr from-primary to-gray-950 bg-clip-text text-transparent"
            >
              {{ coloredTitle[1] }}
            </span>
          </h1>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="text-lg text-balanced text-center font-medium text-gray-600 tracking-tight leading-snug"
            v-html="exchangeWidget?.pre"
          />
        </section>

        <ExchangeWidget
          :list="exchangeWidget?.widget?.list"
          :link="exchangeWidget?.widget?.link"
        />

        <dl
          v-if="exchangeWidget?.stats2"
          class="my-6 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4"
        >
          <div
            v-for="stat in exchangeWidget?.stats2"
            :key="stat.top"
            class="flex flex-col bg-gray-400/5 p-8"
          >
            <dt class="text-sm font-semibold leading-6 text-gray-600">
              {{ stat.bottom }}
            </dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">
              {{ stat.top }}
            </dd>
          </div>
        </dl>
      </UContainer>
    </div>

    <div
      v-if="exchangeWidget?.section2?.content"
      class="bg-gray-900 py-8 lg:py-8 mt-4 lg:mt-4"
    >
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none dark">
          <p class="text-base font-semibold leading-7 text-orange-400">
            {{ exchangeWidget?.section2?.pretitle }}
          </p>
          <h2 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {{ exchangeWidget?.section2?.title }}
          </h2>
          <UPageBody
            prose
          >
            <div v-html="exchangeWidget?.section2?.content" />
          </UPageBody>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg {
  background-image: url('/gradient.png');
  background-repeat: repeat-x;
  background-position: center top;
}
</style>
